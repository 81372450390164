<template>
  <button v-clipboard:copy="text" v-clipboard:success="onCopy" type="button" @click.prevent="">
    <slot v-if="!showSuccess">
      Copy
    </slot>
    <slot v-else name="success">
      Copied
    </slot>
  </button>
</template>

<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

export default {
  props: ['text'],
  data () {
    return { showSuccess: false }
  },
  methods: {
    onCopy () {
      this.showSuccess = true
      setTimeout(() => (this.showSuccess = false), 800)
    }
  }
}
</script>
